<template>
  <vx-card class="main-box top-zero-radius md:w-1/2 mx-auto">
    <vs-button class="w-full"
               color="danger"
               @click="truncateAccountingDatabase">
      پاکسازی دیتابیس حسابداری
    </vs-button>
  </vx-card>
</template>

<script>
import {truncateAccountingDatabase} from '@/http/requests/setting'

export default {
  name: 'advancedSetting',
  data () {
    return {
      actions: [
        {
          /*toolbar: [
            {
              id: {name: 'insertAccessGroup'},
              type: 'link',
              i18n: 'setting.access.actions.insert',
              color: 'success',
              icon: '',
              iconPack: '',
            }
          ],*/
          leftToolbar: [
            {
              id: {name: 'Settings'},
              type: 'link',
              icon: 'icon-chevron-left',
              iconPack: 'feather'
            }
          ]
        }
      ]
    }
  },
  created () {
    setTimeout(() => {
      this.$store.dispatch('updateNavbarActions', this.actions[0])
      this.$store.dispatch('updateContentNavbarStyle', 'sticky')
      this.$store.dispatch('updateContentNavbarClass', 'md:w-1/2 mx-auto')
    }, 50)
  },
  methods: {
    truncateAccountingDatabase () {
      truncateAccountingDatabase().then(response => {
        this.$vs.notify({
          title: 'پیغام',
          text: 'دیتابیس حسابداری با موفقیت پاکسازی شد',
          time: 2400,
          color: 'success',
          icon: 'icon-check',
          iconPack: 'feather'
        })
      }).catch(error => {
        this.$vs.notify({
          title: 'خطا',
          text: 'پاکسازی دیتابیس حسابداری با خطا همراه شد',
          time: 2400,
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
